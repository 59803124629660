import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import { SITE_PADDING, SITE_PADDING_MOBILE, SITE_WIDTH, } from '@stories/Components/Containers/SiteWide/SiteWide.styles';
import styled, { css } from 'styled-components';
import IconCtaListStyles from './components/IconCtaList/IconCtaList.styles';
import IconCtaListMobileStyles from './components/IconCtaListMobile/IconCtaListMobile.styles';
import LogoStyles from './components/Logo/Logo.styles';
import NaviconStyles from './components/Navicon/Navicon.styles';
import SearchFieldStyles from './components/SearchField/SearchField.styles';
import TopLinkListStyles from './components/TopLinkList/TopLinkList.styles';
import YourSolutionCtaStyles from './components/YourSolutionCta/YourSolutionCta.styles';
import brand from '@helpers/brand';
import { m } from 'framer-motion';
const Container = styled.header `
  ${({ isActive }) => isActive
    ? css `
          position: fixed;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          display: block;
          background: #fff;
          z-index: 8001;
          overflow-y: auto;
          overflow-x: hidden;
        `
    : null}
`;
const Top = styled.div `
  display: flex;
  background: #fff;
  height: 140px;

  @media ${until(Device.TabletLarge)} {
    height: 65px;
  }
`;
const TopInner = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 auto;
  max-width: calc(${SITE_WIDTH}px + (${SITE_PADDING}px * 2));
  padding: 14px ${SITE_PADDING}px 0;
  width: 100%;

  @supports (display: grid) {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(12, 1fr);
  }

  @media ${until(Device.DesktopSmall)} {
    display: flex;
    gap: 0; // resetting as only chrome supports this with flex.
    max-width: none;
    padding: 0 ${SITE_PADDING_MOBILE}px;
  }

  ${TopLinkListStyles.Container} {
    position: absolute;
    top: 0;
    left: ${SITE_PADDING}px;

    @media ${until(Device.DesktopSmall)} {
      display: none;
    }
  }

  ${NaviconStyles.Container} {
    @media ${from(Device.DesktopSmall)} {
      display: none;
    }
  }

  ${LogoStyles.Container} {
    flex: 1 1 auto;
    grid-column: 1 / span 3;

    @media ${until(Device.DesktopSmall)} {
      margin-left: 22px;
      max-width: 170px;
    }
  }

  ${SearchFieldStyles.Container} {
    flex: 1 1 auto;
    grid-column: 4 / span 4;
    margin-left: auto;
    margin-right: auto;

    @supports (display: grid) {
      margin-left: 0;
      margin-right: 0;
    }

    @media ${from(Device.DesktopSmall)} {
      max-width: 526px;
    }
  }

  ${IconCtaListStyles.Container} {
    flex: 1 1 auto;
    grid-column: 8 / span 5;
    justify-content: flex-end;
    margin-left: auto;

    @supports (display: grid) {
      margin-left: 0;
    }

    @media ${until(Device.DesktopSmall)} {
      display: none;
    }
  }

  ${IconCtaListMobileStyles.Container} {
    justify-content: flex-end;
    margin-left: auto;

    @media ${from(Device.DesktopSmall)} {
      display: none;

      @supports (display: grid) {
        margin-left: 0;
      }
    }
  }
`;
const Bottom = styled.div `
  position: relative;
  display: flex;
  background: #f6f6f6;
  height: 45px;

  @media ${until(Device.DesktopSmall)} {
    height: auto;
    min-height: calc(100vh - 65px);
  }

  &[data-is-portal='true'] {
    overflow: hidden;
  }
`;
const BottomInner = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: calc(${SITE_WIDTH}px + (${SITE_PADDING}px * 2));
  padding: 0 ${SITE_PADDING}px;
  width: 100%;

  ${YourSolutionCtaStyles.Container} {
    margin-left: auto;
  }

  @media ${until(Device.DesktopSmall)} {
    display: block;
    height: 100%;
    padding: 0px 0px 150px 0px;
  }

  ${IconCtaListStyles.Container} {
    display: block;

    @media ${from(Device.DesktopSmall)} {
      display: none;
    }
  }
`;
const NavOverlay = styled.div `
  display: flex;
  background: #fff;
  position: relative;
  height: 100%;
  padding: 10px;
`;
const Telephone = styled.a `
  display: flex;
  background: #fff;
  position: relative;
  height: unset;
  padding: 10px;

  &:hover {
    text-decoration: none;
  }

  p {
    position: relative;
    font-weight: 400;
    font-size: 16px;
    display: block;
    color: #690;
    margin: 0;

    @media ${from(Device.DesktopSmall)} {
      display: none;
    }

    @media ${from(Device.DesktopLarge)} {
      display: flex;
      align-items: center;
      margin: auto;
      font-size: 25px;
      font-weight: bold;
      height: 100%;
    }
  }
  img {
    width: 33px;
    height: 25px;
    position: relative;
    margin-right: 10px;
    padding-right: 10px;

    @media ${from(Device.DesktopSmall)} {
      padding: 2px;
      margin-right: 0px;
      padding-right: 0px;
    }

    @media ${from(Device.DesktopLarge)} {
      padding: 2px;
      margin-right: 10px;
      padding-right: 10px;
    }
  }

  @media ${from(Device.DesktopSmall)} {
    margin-left: 50px;
    height: 100%;

    &:before {
      content: '';
      position: absolute;
      background: rgb(255, 255, 255);
      height: 100%;
      width: 2000px;
      left: -40px;
      top: 0px;
      clip-path: polygon(1% 0%, 100% 0px, 100% 100%, 0% 100%);
    }
  }

  @media ${from(Device.DesktopLarge)} {
    margin-left: 150px;
    height: 100%;
  }
`;
const WelcomeMessage = styled.span `
  display: block;
  color: #575757;
  ${fonts.DaxPro.Regular};
  font-size: 14px;
  line-height: 20px;
  padding: 4px 12px;

  > span {
    display: inline-block;
  }

  a {
    display: inline-block;
    color: #6f9d20;
    padding-left: 6px;

    &::before {
      content: '|';
      display: inline-block;
      padding-right: 6px;
    }

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
`;
const WelcomeMessageDesktop = styled(WelcomeMessage) `
  position: absolute;
  top: 0;
  right: 20px;

  @media ${until(Device.DesktopSmall)} {
    display: none;
  }
`;
const WelcomeMessageMobile = styled(WelcomeMessage) `
  margin-top: 16px;

  @media ${from(Device.DesktopSmall)} {
    display: none;
  }
`;
const Mobile = styled.div `
  display: block;

  @media ${from(Device.DesktopSmall)} {
    display: none;
  }
`;
const Desktop = styled.div `
  display: none;

  @media ${from(Device.DesktopSmall)} {
    display: block;
  }
`;
const YourSolutionModal = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            opacity: 0,
            scale: 0.8,
        },
        visible: {
            opacity: 1,
            scale: 1,
        },
    },
    transition: {
        duration: 0.5,
    },
})) `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${brand.white};
  z-index: 8001; // Search bar on homepage is 8000.... //
`;
export const SiteHeaderStyles = {
    Bottom,
    BottomInner,
    Container,
    NavOverlay,
    Top,
    TopInner,
    Telephone,
    WelcomeMessageDesktop,
    WelcomeMessageMobile,
    Mobile,
    Desktop,
    YourSolutionModal,
};
