/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-use-before-define */
import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, until } from '@helpers/media';
import styled, { css } from 'styled-components';
const Container = styled.ul `
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @media ${until(Device.DesktopSmall)} {
    flex-direction: column;
    margin: 10px 0 0;
    padding: 0 10px;
  }
`;
const Item = styled.li `
  display: block;
  margin: 0;
  padding: 0 24px;
  position: relative;

  @media ${until(Device.DesktopSmall)} {
    padding: 0;
  }
`;
const linkStyles = css `
  display: flex;
  align-items: center;
  background: none;
  border: 0;
  color: #333333;
  flex-direction: column;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  ${({ hasIndicator }) => hasIndicator
    ? css `
          &:after {
            content: '';
            position: absolute;
            top: 0px;
            right: 35%;
            transform: translateX(-50%);
            width: 9px;
            height: 9px;
            border-radius: 50%;
            z-index: 2;
            background: ${brand.accent.orange};
            transition: transform 0.15s ease-out;
          }
        `
    : null}

  &:focus,
  &:hover {
    background: none;
    border: 0;
    color: #333333;
    text-decoration: none;

    ${() => Label} {
      background-size: 100% 100%;
      color: #fff;
    }

    ${() => Icon} {
      transform: translateY(-4px) scale(1.1);
    }

    &:after {
      transform: translateX(-50%) translateY(-4px) scale(1.1);
    }
  }

  @media ${until(Device.DesktopSmall)} {
    flex-direction: row;
    padding: 10px 0;
  }
`;
const Link = styled.a `
  ${linkStyles};
`;
const Button = styled.button.attrs({ type: 'button' }) `
  ${linkStyles};
`;
const Label = styled.span `
  display: block;
  background: linear-gradient(to right, #6f9d20, #6f9d20) no-repeat;
  background-size: 0% 100%;
  color: #333333;
  ${fonts.DaxPro.Regular};
  font-size: 14px;
  line-height: 18px;
  margin: -4px;
  padding: 4px;
  text-align: center;
  transition: background-size 0.15s ease-out, color 0.15s ease-out;
`;
const Icon = styled.img `
  display: block;
  height: 24px;
  margin: 0 0 8px;
  object-fit: contain;
  object-position: center center;
  padding: 0;
  transform: translateY(0);
  transform-origin: center bottom;
  transition: transform 0.15s ease-out;
  width: 24px;

  @media ${until(Device.DesktopSmall)} {
    margin: 0 12px 0 0;
  }
`;
export default {
    Button,
    Container,
    Icon,
    Item,
    Label,
    Link,
};
