import SvgUser from '@img/icons/user.svg';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSSR } from '@helpers/ssr';
import { LoginRedirectType } from '@redux/ducks/app/app.reducer';
import S from './IconCtaList.styles';
export var IconCtaType;
(function (IconCtaType) {
    IconCtaType[IconCtaType["Link"] = 0] = "Link";
    IconCtaType[IconCtaType["Basket"] = 1] = "Basket";
    IconCtaType[IconCtaType["TechnicalLibrary"] = 2] = "TechnicalLibrary";
})(IconCtaType || (IconCtaType = {}));
const IconCtaList = ({ items = [], loginText = '', myProfileText, myProfileUrl, isLoggedIn, }) => {
    const appBasketCount = useSelector((x) => x.app.basketItemsCount ?? 0);
    const basketState = useSelector((x) => x.basket.basket);
    const hasItemInBasket = isSSR()
        ? appBasketCount > 0
        : basketState !== null && basketState.order !== null && basketState.order.orderLines.length > 0;
    const dispatch = useDispatch();
    return (React.createElement(S.Container, null,
        !isLoggedIn && (React.createElement(S.Item, null,
            React.createElement(S.Button, { onClick: () => dispatch({
                    type: 'APP/SET_LOGIN_MODAL',
                    payload: LoginRedirectType.Default,
                }), hasIndicator: false },
                React.createElement(S.Icon, { alt: "Login Icon", role: "presentation", src: SvgUser }),
                React.createElement(S.Label, null, loginText)))),
        isLoggedIn && (React.createElement(S.Item, null,
            React.createElement(S.Link, { href: myProfileUrl, hasIndicator: false },
                React.createElement(S.Icon, { alt: "My Profile Icon", role: "presentation", src: SvgUser }),
                React.createElement(S.Label, null, myProfileText)))),
        items.map((item) => {
            switch (item.type) {
                default:
                case IconCtaType.Link: {
                    return (React.createElement(S.Item, { key: item.id },
                        React.createElement(S.Link, { href: item.url, hasIndicator: false },
                            React.createElement(S.Icon, { alt: item.text, role: "presentation", src: item.icon }),
                            React.createElement(S.Label, null, item.text))));
                }
                case IconCtaType.TechnicalLibrary: {
                    return (React.createElement(S.Item, { key: item.id, onClick: () => {
                            if (isLoggedIn) {
                                window.location.replace(item.url);
                            }
                            else {
                                dispatch({
                                    type: 'APP/SET_LOGIN_MODAL',
                                    payload: LoginRedirectType.TechnicalLibrary,
                                });
                            }
                        } },
                        React.createElement(S.Button, { hasIndicator: false },
                            React.createElement(S.Icon, { alt: item.text, role: "presentation", src: item.icon }),
                            React.createElement(S.Label, null, item.text))));
                }
                case IconCtaType.Basket: {
                    return (React.createElement(S.Item, { key: item.id },
                        React.createElement(S.Link, { href: item.url, hasIndicator: hasItemInBasket },
                            React.createElement(S.Icon, { alt: item.text, role: "presentation", src: item.icon }),
                            React.createElement(S.Label, null, item.text))));
                }
            }
        })));
};
export default IconCtaList;
