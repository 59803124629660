import AuthService from '@core/auth/services/AuthService';
import { HydrateOption } from '@core/enums';
import withRedux from '@helpers/withRedux';
import withWidget from '@hoc/withWidget';
import useBodyScrollLock from '@hooks/useBodyScrollLock';
import phone from '@img/icons/phone.svg';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import IconCtaList from './components/IconCtaList/IconCtaList';
import IconCtaListMobile from './components/IconCtaListMobile/IconCtaListMobile';
import Logo from './components/Logo/Logo';
import Navicon from './components/Navicon/Navicon';
import Navigation from './components/Navigation/Navigation';
import NavigationMobile from './components/NavigationMobile/NavigationMobile';
import SearchField from './components/SearchField/SearchField';
import TopLinkList from './components/TopLinkList/TopLinkList';
import TopLinkListMobile from './components/TopLinkListMobile/TopLinkListMobile';
import YourSolutionCta from './components/YourSolutionCta/YourSolutionCta';
import { AnimatePresence } from 'framer-motion';
import YourSolutionRootWidget from '@stories/Widgets/YourSolution/YS/YourSolutionRoot.widget';
import { SiteHeaderStyles as S } from './SiteHeader.styles';
import { withMotion } from '@hoc/withMotion';
const SiteHeader = ({ logoCloudinaryPath, logoNavUrl, enquiryCtaUrl, iconCtaListDesktop, iconCtaListMobile, isPortalHeader = false, searchResultsUrl, yourSolutionProps, showYourSolutionCta = false, navigation = [], topLinks, telephoneNumber, translations, homeUrl, }) => {
    const { isLoggedIn, welcomeMessage, myProfileUrl } = useSelector((x) => x.app);
    const [showNavigationMobile, setShowNavigationMobile] = useState(false);
    const [showSearchMobile, setShowSearchMobile] = useState(false);
    const [activeMobileNavigationId, setActiveMobileNavigationId] = useState('');
    const [yspVisible, setYSPVisible] = useState(false);
    const lockRef = useBodyScrollLock(showNavigationMobile);
    useBodyScrollLock(yspVisible);
    const handleSignOut = async () => {
        try {
            const res = await AuthService.signOut();
            if (!res.status.success) {
                return;
            }
            window.location.href = res.result.redirectUrl;
        }
        catch (error) {
            window.location.href = '/';
        }
    };
    const top = (React.createElement(S.Top, null,
        React.createElement(S.TopInner, null,
            React.createElement(Navicon, { active: showNavigationMobile, onClick: () => {
                    setActiveMobileNavigationId('');
                    setShowNavigationMobile(!showNavigationMobile);
                } }),
            React.createElement(Logo, { title: translations['gf.home'], url: logoNavUrl, logoCloudinaryPath: logoCloudinaryPath }),
            React.createElement(SearchField, { searchResultsUrl: searchResultsUrl, showSearchMobile: showSearchMobile, placeholderText: translations['gf.header.search.placeholder'], searchText: translations['gf.header.search'], closeText: translations['gf.header.close'], onClose: () => {
                    setShowSearchMobile(false);
                } }),
            React.createElement(IconCtaList, { items: iconCtaListDesktop, isLoggedIn: isLoggedIn, myProfileText: translations['gf.myprofile'], myProfileUrl: myProfileUrl, loginText: translations['gf.header.login'] }),
            React.createElement(IconCtaListMobile, { isLoggedIn: isLoggedIn, enquiryCtaUrl: enquiryCtaUrl, searchText: translations['gf.header.search'], myProfileText: translations['gf.myprofile'], myProfileUrl: myProfileUrl, loginText: translations['gf.header.login'], enquiryText: translations['gf.header.enquiry'], onSearchClick: (event) => {
                    event.preventDefault();
                    setShowSearchMobile(!showSearchMobile);
                } }),
            React.createElement(TopLinkList, { items: topLinks }),
            welcomeMessage && (React.createElement(S.WelcomeMessageDesktop, null,
                React.createElement("span", null, welcomeMessage),
                React.createElement("a", { href: "#", onClick: (event) => {
                        event.preventDefault();
                        handleSignOut();
                    } }, translations['gf.header.signout']))))));
    const bottom = (React.createElement(S.Bottom, { active: showNavigationMobile, "data-is-portal": isPortalHeader },
        React.createElement(S.BottomInner, null,
            React.createElement(Navigation, { items: navigation, homeUrl: homeUrl, isPortalHeader: isPortalHeader }),
            React.createElement(NavigationMobile, { activeId: activeMobileNavigationId, items: navigation, isPortalHeader: isPortalHeader, onItemClick: (id) => setActiveMobileNavigationId(id) }),
            activeMobileNavigationId === '' && (React.createElement(Fragment, null,
                showYourSolutionCta && !isPortalHeader && yourSolutionProps && React.createElement(YourSolutionCta, { yspVisible: yspVisible, setYSPVisible: setYSPVisible }),
                React.createElement(IconCtaList, { items: iconCtaListMobile, isLoggedIn: isLoggedIn, myProfileText: translations['gf.myprofile'], myProfileUrl: myProfileUrl, loginText: translations['gf.header.login'] }),
                isLoggedIn && (React.createElement(S.WelcomeMessageMobile, null,
                    React.createElement("span", null, welcomeMessage),
                    React.createElement("a", { href: "#", onClick: (event) => {
                            event.preventDefault();
                            handleSignOut();
                        } }, translations['gf.header.signout']))),
                React.createElement(TopLinkListMobile, { items: topLinks }),
                !isPortalHeader &&
                    React.createElement(S.Telephone, { href: `tel:${telephoneNumber}`, "aria-label": "Telephone Number" },
                        React.createElement("img", { src: phone, loading: "lazy", alt: "Telephone Icon" }),
                        React.createElement("p", null, telephoneNumber)))))));
    return (React.createElement(React.Fragment, null,
        React.createElement(S.Mobile, null,
            React.createElement(S.Container, { isActive: false }, top),
            showNavigationMobile ? (React.createElement(S.Container, { isActive: true, ref: lockRef },
                top,
                bottom)) : null),
        React.createElement(S.Desktop, null,
            React.createElement(S.Container, { isActive: false },
                top,
                bottom)),
        React.createElement(AnimatePresence, null, yourSolutionProps && yspVisible && (React.createElement(S.YourSolutionModal, { key: 'YourSolutionModal' },
            React.createElement(YourSolutionRootWidget, { ...yourSolutionProps, closeModal: () => setYSPVisible(false) }))))));
};
export default withWidget(withRedux(withMotion(SiteHeader)), 'SiteHeader', {
    hydrate: HydrateOption.Always,
});
